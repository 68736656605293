import React, { useCallback, useEffect, useState } from "react"
import { Row, Col, Typography, Drawer, Button } from "antd"
import { Link } from "gatsby"

import "./Layout.less"
import Icon from "resources/img/wispaylogo.svg"
// import Select from "react-select"

const Header = ({ headerVariant, headerBackground }) => {
  const [background, setBackground] = useState(
    headerVariant === "blue" ? "blue" : "normal"
  )
  const [visible, setVisible] = useState(false)

  const listenScrollEvent = useCallback(() => {
    if (window.scrollY > 100) {
      setBackground(headerBackground)
    } else {
      setBackground(headerVariant === "blue" ? "blue" : "normal")
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)
    return () => {
      window.removeEventListener("scroll", listenScrollEvent)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // const optionslanguage = [
  //   { value: "English", label: "EN" },
  //   { value: "Indonesia", label: "IDN" },
  //   { value: "Rusian", label: "RUS" },
  // ]
  // const options = [
  //   { value: "English", label: "English" },
  //   { value: "Indonesia", label: "Indonesia" },
  //   { value: "Rusian", label: "Rusian" },
  // ]
  return (
    <div className={`site-header header-${background} text-header-black`}>
      <div className="section-header">
        <Col xl={20} lg={20} md={24} sm={24} xs={24} className="header-col">
          <Row
            justify="space-between"
            align="left"
            style={{ alignItems: "center" }}
          >
            <Col xl={4} lg={4} md={4} sm={4} xs={4}>
              <Link to="/">
                <img src={Icon} alt="WisWis logo" className="icon-header" />
              </Link>
            </Col>
            <Col xl={6} lg={6} md={0} sm={0} xs={0} className="menu-item">
              {/* <Row align="middle">
                <Col
                  style={{ width: "100%", textAlign: "center" }}
                  className="col-navigation"
                >
                  <Link to={"#solution"}>
                    <Typography.Text className="text-header">
                      Solution
                    </Typography.Text>
                  </Link>
                  <Link to={"#benefit"}>
                    <Typography.Text className="text-header">
                      Benefits
                    </Typography.Text>
                  </Link>
                </Col>
              </Row> */}
            </Col>
            <Col
              xl={10}
              lg={10}
              md={16}
              sm={16}
              xs={16}
              className="col-download"
            >
              <Row
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Col className="languange-wraper" style={{ marginRight: 4 }}>
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <path
                      d="M7.99992 15.1667C4.04659 15.1667 0.833252 11.9534 0.833252 8.00004C0.833252 4.04671 4.04659 0.833374 7.99992 0.833374C11.9533 0.833374 15.1666 4.04671 15.1666 8.00004C15.1666 11.9534 11.9533 15.1667 7.99992 15.1667ZM7.99992 1.83337C4.59992 1.83337 1.83325 4.60004 1.83325 8.00004C1.83325 11.4 4.59992 14.1667 7.99992 14.1667C11.3999 14.1667 14.1666 11.4 14.1666 8.00004C14.1666 4.60004 11.3999 1.83337 7.99992 1.83337Z"
                      fill="#191919"
                    />
                    <path
                      d="M6.00016 14.5H5.33349C5.06016 14.5 4.83349 14.2733 4.83349 14C4.83349 13.7267 5.04682 13.5067 5.32016 13.5C4.27349 9.92667 4.27349 6.07333 5.32016 2.5C5.04682 2.49333 4.83349 2.27333 4.83349 2C4.83349 1.72667 5.06016 1.5 5.33349 1.5H6.00016C6.16016 1.5 6.31349 1.58 6.40682 1.70667C6.50016 1.84 6.52682 2.00667 6.47349 2.16C5.22016 5.92667 5.22016 10.0733 6.47349 13.8467C6.52682 14 6.50016 14.1667 6.40682 14.3C6.31349 14.42 6.16016 14.5 6.00016 14.5Z"
                      fill="#191919"
                    />
                    <path
                      d="M9.99986 14.5C9.94652 14.5 9.89319 14.4934 9.83986 14.4734C9.57986 14.3867 9.43319 14.1 9.52652 13.84C10.7799 10.0734 10.7799 5.9267 9.52652 2.15337C9.43986 1.89337 9.57986 1.60671 9.83986 1.52004C10.1065 1.43337 10.3865 1.57337 10.4732 1.83337C11.7999 5.8067 11.7999 10.18 10.4732 14.1467C10.4065 14.3667 10.2065 14.5 9.99986 14.5Z"
                      fill="#191919"
                    />
                    <path
                      d="M8 11.4667C6.14 11.4667 4.28667 11.2067 2.5 10.68C2.49333 10.9467 2.27333 11.1667 2 11.1667C1.72667 11.1667 1.5 10.94 1.5 10.6667V10C1.5 9.84003 1.58 9.68669 1.70667 9.59336C1.84 9.50003 2.00667 9.47336 2.16 9.52669C5.92667 10.78 10.08 10.78 13.8467 9.52669C14 9.47336 14.1667 9.50003 14.3 9.59336C14.4333 9.68669 14.5067 9.84003 14.5067 10V10.6667C14.5067 10.94 14.28 11.1667 14.0067 11.1667C13.7333 11.1667 13.5133 10.9534 13.5067 10.68C11.7133 11.2067 9.86 11.4667 8 11.4667Z"
                      fill="#191919"
                    />
                    <path
                      d="M13.9998 6.49995C13.9464 6.49995 13.8931 6.49328 13.8398 6.47328C10.0731 5.21995 5.91977 5.21995 2.15311 6.47328C1.88644 6.55995 1.60644 6.41995 1.51977 6.15995C1.43977 5.89328 1.57977 5.61328 1.83977 5.52662C5.81311 4.19995 10.1864 4.19995 14.1531 5.52662C14.4131 5.61328 14.5598 5.89995 14.4664 6.15995C14.4064 6.36662 14.2064 6.49995 13.9998 6.49995Z"
                      fill="#191919"
                    />
                  </svg> */}
                </Col>
                <Col className="languange-wraper">
                  <Row>
                    <Col
                      style={{ width: "100%", textAlign: "center" }}
                      className="col-navigation"
                    >
                      <Link to={"#solution"}>
                        <Typography.Text className="text-header">
                          Solution
                        </Typography.Text>
                      </Link>{" "}
                      {/* <div className="line-languange"> </div> */}
                      <Link to={"#benefit"}>
                        <Typography.Text className="text-header">
                          Benefits
                        </Typography.Text>
                      </Link>{" "}
                      {/* <div className="line-languange"> </div> */}
                    </Col>
                  </Row>
                </Col>
                <Col className="header-space languange-wraper">
                  {/* <div className="line-languange"> </div> */}
                </Col>
                <Col className="contactus-header">
                  <a href="mailto:info@bintangdigitalasia.com">
                    <Button className="button-black">
                      <p>Get Started</p>
                    </Button>
                  </a>
                </Col>
              </Row>
            </Col>
            <Col
              className="container-icon-menu"
              onClick={() => setVisible(!visible)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
              >
                <path
                  d="M2.75 6.25H21.25M7.75 12.25H21.25M4.75 18.25H21.25"
                  stroke="#191919"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                />
              </svg>
            </Col>
          </Row>
        </Col>
      </div>
      <Drawer
        title={
          <Col span={4}>
            <Link to="/">
              <img src={Icon} alt="WisWis logo" className="icon-header" />
            </Link>
          </Col>
        }
        placement={"left"}
        closable={true}
        onClose={() => setVisible(!visible)}
        visible={visible}
        key={"left"}
        closeIcon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="17"
            viewBox="0 0 20 17"
            fill="none"
          >
            <path
              d="M3 15.5409L17.1421 1.39876"
              stroke="#191919"
              stroke-width="2"
              stroke-linecap="round"
            />
            <path
              d="M3 1.3988L17.1421 15.5409"
              stroke="#191919"
              stroke-width="2"
              stroke-linecap="round"
            />
          </svg>
        }
        contentWrapperStyle={{ width: "100%" }}
      >
        <div className="text-header-HP container-drawer">
          <Col span={24} className="menu-responsive">
            <Link onClick={() => setVisible(false)} to={"#solution"}>
              <Typography.Text className="text-header-HP">
                Solution
              </Typography.Text>
            </Link>
          </Col>
          <Col span={24} className="menu-responsive">
            <Link onClick={() => setVisible(false)} to={"#benefit"}>
              <Typography.Text className="text-header-HP">
                Benefits
              </Typography.Text>
            </Link>
          </Col>
          {/* <Row className="Row-button-HP">
            <Col span={24}>
              <div className="button-black-full-width">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                >
                  <path
                    d="M7.99992 15.9643C4.04659 15.9643 0.833252 12.751 0.833252 8.79765C0.833252 4.84431 4.04659 1.63098 7.99992 1.63098C11.9533 1.63098 15.1666 4.84431 15.1666 8.79765C15.1666 12.751 11.9533 15.9643 7.99992 15.9643ZM7.99992 2.63098C4.59992 2.63098 1.83325 5.39765 1.83325 8.79765C1.83325 12.1976 4.59992 14.9643 7.99992 14.9643C11.3999 14.9643 14.1666 12.1976 14.1666 8.79765C14.1666 5.39765 11.3999 2.63098 7.99992 2.63098Z"
                    fill="white"
                  />
                  <path
                    d="M6.00016 15.2976H5.33349C5.06016 15.2976 4.83349 15.0709 4.83349 14.7976C4.83349 14.5243 5.04682 14.3043 5.32016 14.2976C4.27349 10.7243 4.27349 6.87094 5.32016 3.29761C5.04682 3.29094 4.83349 3.07094 4.83349 2.79761C4.83349 2.52427 5.06016 2.29761 5.33349 2.29761H6.00016C6.16016 2.29761 6.31349 2.37761 6.40682 2.50427C6.50016 2.63761 6.52682 2.80427 6.47349 2.95761C5.22016 6.72427 5.22016 10.8709 6.47349 14.6443C6.52682 14.7976 6.50016 14.9643 6.40682 15.0976C6.31349 15.2176 6.16016 15.2976 6.00016 15.2976Z"
                    fill="white"
                  />
                  <path
                    d="M9.99986 15.2976C9.94652 15.2976 9.89319 15.291 9.83986 15.271C9.57986 15.1843 9.43319 14.8976 9.52652 14.6376C10.7799 10.871 10.7799 6.72431 9.52652 2.95098C9.43986 2.69098 9.57986 2.40431 9.83986 2.31765C10.1065 2.23098 10.3865 2.37098 10.4732 2.63098C11.7999 6.60431 11.7999 10.9776 10.4732 14.9443C10.4065 15.1643 10.2065 15.2976 9.99986 15.2976Z"
                    fill="white"
                  />
                  <path
                    d="M8 12.2643C6.14 12.2643 4.28667 12.0043 2.5 11.4776C2.49333 11.7443 2.27333 11.9643 2 11.9643C1.72667 11.9643 1.5 11.7376 1.5 11.4643V10.7976C1.5 10.6376 1.58 10.4843 1.70667 10.391C1.84 10.2976 2.00667 10.271 2.16 10.3243C5.92667 11.5776 10.08 11.5776 13.8467 10.3243C14 10.271 14.1667 10.2976 14.3 10.391C14.4333 10.4843 14.5067 10.6376 14.5067 10.7976V11.4643C14.5067 11.7376 14.28 11.9643 14.0067 11.9643C13.7333 11.9643 13.5133 11.751 13.5067 11.4776C11.7133 12.0043 9.86 12.2643 8 12.2643Z"
                    fill="white"
                  />
                  <path
                    d="M13.9998 7.29756C13.9464 7.29756 13.8931 7.29089 13.8398 7.27089C10.0731 6.01756 5.91977 6.01756 2.15311 7.27089C1.88644 7.35756 1.60644 7.21756 1.51977 6.95756C1.43977 6.69089 1.57977 6.41089 1.83977 6.32422C5.81311 4.99756 10.1864 4.99756 14.1531 6.32422C14.4131 6.41089 14.5598 6.69756 14.4664 6.95756C14.4064 7.16422 14.2064 7.29756 13.9998 7.29756Z"
                    fill="white"
                  />
                </svg>
                <Select
                  options={options}
                  className="language-select"
                  defaultValue={options[0]}
                />
              </div>
            </Col>
          </Row> */}
        </div>
      </Drawer>
    </div>
  )
}

export default Header
