import React from "react"
import { Col, Row, Typography } from "antd"

import WispayLogoFinal from "resources/img/wispaylogo.svg"
// import Fb from "resources/img/footer/facebook.png"
// import Ig from "resources/img/footer/Instagram.png"

const Footer = () => {
  return (
    <>
      <div className="container-footer">
        <Col xl={20} lg={20} md={0} sm={0} xs={0} className="Col-Footer">
          <Row>
            <Col span={24}>
              <img
                src={WispayLogoFinal}
                alt="logo WisWis"
                className="wispay-footer-logo"
              />
            </Col>
            <Col span={18} style={{ display: "flex", alignItems: "center" }}>
              <Typography.Text className="copyright-text">
                Jl HOS Cokroaminoto No. 98 Menteng, Jakarta Pusat - 10310,
                Indonesia
              </Typography.Text>
            </Col>
            {/* <Col
              span={6}
              style={{
                display: "flex",
                alignItems: "center",
              }}
              className="nav-foot"
            >
              <Typography.Text className="nav-footer">
                <a href="mailto:help@bintangdigitalasia.com">
                  Customer Support
                </a>
              </Typography.Text>
            </Col> */}
            {/* <Col span={6} className="medsos">
              <Row className="medsos-logo">
                <Col span={8}>
                  <img src={Ig} alt="instagram logo" />
                </Col>
                <Col span={8}>
                  <img src={Ig} alt="instagram logo" />
                </Col>
                <Col span={8}>
                  <img src={Fb} alt="facebook logo" />
                </Col>
              </Row>
            </Col> */}
          </Row>
          <Col span={24} className="copyright">
            <Typography.Text className="copyright-text">
              Copyright ⓒ 2023 PT Bintang Digital Asia. All rights reserved.
            </Typography.Text>
          </Col>
        </Col>
        {/* <Col span={24} className="medsos-mobile">
          <Row className="medsos-logo">
            <Col span={8}>
              <img src={Ig} alt="instagram logo" />
            </Col>
            <Col span={8}>
              <img src={Fb} alt="facebook logo" />
            </Col>
            <Col span={8}>
              <img src={Fb} alt="facebook logo" />
            </Col>
          </Row>
        </Col> */}
        <Col span={24} className="copyright-mobile">
          <Typography.Text className="copyright-mobile-text">
            Copyright ⓒ 2023 PT Bintang Digital Asia <br />
            All rights reserved.
          </Typography.Text>
        </Col>
      </div>
    </>
  )
}

export default Footer
